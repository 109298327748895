import axios from "axios";
import { ParseError, parsePhoneNumberWithError } from "libphonenumber-js";
import moment from "moment/moment";

const getBody = (params) => {
  const { price, mark, model, year, mileage, vin, equipment } = params;
  const { TYPE, FIRST_NAME, LAST_NAME, EMAIL, PHONE, COMMENTS } = params;

  const id = new Date().getTime();
  const date = moment().format("YYYY-MM-DDTHH:mm:ssZ");

  let WITH_EMAIL = "";
  let WITH_PHONE = "";

  if (EMAIL) {
    WITH_EMAIL = `<email preferredcontact="1">${EMAIL}</email>`;
  }

  if (PHONE) {
    try {
      const phoneNumber = parsePhoneNumberWithError(PHONE);

      console.log(phoneNumber);

      if (phoneNumber.isValid()) {
        WITH_PHONE = `<phone type="voice" time="nopreference" preferredcontact="0">${PHONE}</phone>`;
      }
    } catch (error) {
      if (error instanceof ParseError) {
        // Not a phone number, non-existent country, etc.
        console.log(error.message);
      }
    }
  }

  let TEXT = [TYPE];
  if (COMMENTS) {
    TEXT.push(COMMENTS);
  }

  if (!WITH_EMAIL && !WITH_PHONE) {
    throw Error("Please enter CORRECT EMAIL or PHONE");
  }

  return `
  <?xml version="1.0" encoding="UTF-8"?><?adf version="1.0"?>
  <adf>
      <prospect status="new">
          <id sequence="1" source="VM">VM-${id}</id>
          <requestdate>${date}</requestdate>
          <vehicle interest="buy" status="used">
              <id>${vin}</id>
              <year>${year}</year>
              <make>${mark}</make>
              <model>${model}</model>
              <vin>${vin}</vin>
              <stock>10114</stock>
              <trim></trim>
              <odometer units="mi">${mileage}</odometer>
              <price type="quote" currency="USD">${price}</price>
              <finance>
                  <method>Financing</method>
                  <amount />
              </finance>
              <extra>${equipment}</extra>
          </vehicle>
          <customer>
              <contact>
                  <name part="first" type="individual">${FIRST_NAME}</name>
                  <name part="last" type="individual">${LAST_NAME}</name>
                  ${WITH_EMAIL}
                  ${WITH_PHONE}
                  <address>
                      <street line="1"></street>
                      <city>Los Angeles</city>
                      <regioncode>CA</regioncode>
                      <postalcode>90009</postalcode>
                      <country>US</country>
                  </address>
              </contact>
              <comments>${TEXT.join("\n")}</comments>
          </customer>
          <vendor>
              <id source="VM">sp379654</id>
              <vendorname>VM</vendorname>
              <contact>
                  <address>
                      <street line="1">3000 Foothill Blvd</street>
                      <city>La Crescenta</city>
                      <regioncode>CA</regioncode>
                      <postalcode>91214</postalcode>
                      <country>US</country>
                  </address>
              </contact>
          </vendor>
          <provider>
              <id sequence="1" source="VM">1</id>
              <name part="full" type="business">VM</name>
              <service>VM</service>
              <url>https://vm.3beep.io</url>
              <email preferredcontact="1">leads@beeplead.com</email>
              <phone type="voice" time="nopreference" preferredcontact="0"></phone>
              <contact>
                  <name part="full" type="business">VM</name>
                  <email preferredcontact="1">leads@beeplead.com</email>
                  <phone type="voice" time="nopreference" preferredcontact="0"></phone>
                  <address>
                      <street line="1">2 Canal Park 4th Flr</street>
                      <city>Cambridge</city>
                      <regioncode>MA</regioncode>
                      <postalcode>02141</postalcode>
                      <country>US</country>
                  </address>
              </contact>
          </provider>
      </prospect>
  </adf>
  `;
};

const send = async (params, email = "lamotors@devhub.3beep.ai") => {
  return await axios({
    method: "post",
    url: "https://api-dev-v2.berthatech.com/test",
    data: {
        email,
        text: getBody(params),
    }
  });
};

export default send;
