import { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Typography, Divider, Button, message } from "antd";
import { Space, Tag, Modal, Input, Form, Select } from "antd";
import MaskedInput from "antd-mask-input";
import data, { types } from "./data";
import "./index.less";
import send from "./send";
import { useParams } from "react-router";

const { Meta } = Card;
const { Text } = Typography;

const initialValues = {
  TYPE: types[0],
  communication_mode: false,
  FIRST_NAME: "",
  LAST_NAME: "",
  COMMENTS: "",
  EMAIL: "",
  PHONE: "",
};

const Screen = () => {
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, mContextHolder] = message.useMessage();

  const rParams = useParams();

  const onSelect = (item) => (type) => () => {
    setSelected(item);
    if (type === "request") {
      setOpen(true);
    } else if (type === "additional") {
      modal.info({
        title: `${item.mark} ${item.model} ${item.year}`,
        content: <p style={{ whiteSpace: "pre-line" }}>{item.equipment}</p>,
        closable: true,
      });
    }
  };

  const ref = useRef();

  const sendMessage = async () => {
    try {
      const values = await ref.current.validateFields();

      if (!values.EMAIL && (!values.PHONE || values.PHONE === "+1__________")) {
        return messageApi.open({
          type: "error",
          content: "Please enter EMAIL or PHONE",
        });
      }

      const params = { ...values, ...selected };

      const email = rParams?.email;

      setLoading(true);
      await send(params, email);
      setLoading(false);
      modal.success(
        {
          title: "Message sent",
          content: "Thank you for your interest. We will contact you shortly.",
          onOk: () => {
            setOpen(false);
            setSelected(null);
            setComment(false);
          },
        },
        10
      );
    } catch (e) {
      if (e.message) {
        modal.error(
          {
            title: "Error",
            content: e.message,
          },
          10
        );
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open && ref.current) {
      ref.current.resetFields();
    }
  }, [open]);

  const colProps = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 8,
    xxl: 8,
  };

  return (
    <>
      {contextHolder}
      {mContextHolder}
      <Row gutter={[20, 20]}>
        {data.map((item) => {
          const title = `${item.mark} ${item.model} ${item.year}`;
          return (
            <Col {...colProps} key={title}>
              <Card
                className="item"
                cover={<img alt="car" src={item.images[0]} />}
              >
                <Meta
                  title={title}
                  description={
                    <Space direction="vertical">
                      <Button
                        size="small"
                        onClick={onSelect(item)("additional")}
                      >
                        additional info
                      </Button>
                      <Text strong>{item.price}</Text>
                      <Divider type="vertical" />
                      <Text className="small-text">{item.additional}</Text>
                    </Space>
                  }
                />
                <Space direction="vertical" className="additional__info">
                  <div className="spaced">
                    <Text>{item.mileage}</Text>
                    <Tag color="success">GOOD DEAL</Tag>
                  </div>
                  <div className="spaced">
                    <Text>{item.location}</Text>
                    <Text className="small-text">{item.extra}</Text>
                  </div>
                </Space>
                <div className="spaced">
                  <a
                    href={`tel:${item.phone}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {item.phone}
                  </a>
                  <Button
                    ghost
                    type="primary"
                    onClick={onSelect(item)("request")}
                  >
                    Request Info
                  </Button>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Modal
        title="Request Information"
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Form ref={ref} initialValues={initialValues}>
          <Form.Item name="TYPE">
            <Select style={{ width: "100%" }} disabled={loading}>
              {types.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="FIRST_NAME">
            <Input placeholder="First name" disabled={loading} />
          </Form.Item>
          <Form.Item name="LAST_NAME">
            <Input placeholder="Last name" disabled={loading} />
          </Form.Item>
          <Form.Item
            name="EMAIL"
            rules={[
              {
                type: "email",
                message: "Please enter correct email address",
              },
            ]}
          >
            <Input placeholder="Email" disabled={loading} />
          </Form.Item>
          <Form.Item name="PHONE">
            <MaskedInput
              mask="+10000000000"
              style={{ width: "100%" }}
              disabled={loading}
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="link"
              onClick={() => setComment(!comment)}
              disabled={loading}
            >
              Add comments
            </Button>
          </Form.Item>
          {comment && (
            <Form.Item name="COMMENTS">
              <Input.TextArea
                disabled={loading}
                placeholder="Enter your message here (optional)."
              />
            </Form.Item>
          )}
          <Button
            block
            type="primary"
            size="large"
            disabled={loading}
            onClick={sendMessage}
          >
            Send
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default Screen;
