import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "antd/dist/reset.css";
import "./App.less";

import Home from "./screens/Home";
import NotFound from "./screens/NotFound";
import Main from "./layouts/Main";

const router = createBrowserRouter([
  {
    path: "/:email?",
    element: <Home />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const App = () => {
  return (
    <Main>
      <RouterProvider router={router} />
    </Main>
  );
};

export default App;
