import { Layout, Row, Col, theme } from "antd";
const { Header, Content } = Layout;

const Main = ({ children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const colProps = {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 2 },
    md: { span: 20, offset: 2 },
    lg: { span: 18, offset: 3 },
    xl: { span: 18, offset: 3 },
    xxl: { span: 14, offset: 5 },
  };

  return (
    <Row>
      <Col {...colProps}>
        <Layout className="main__layout">
          <Header
            className="header spaced"
            style={{ background: colorBgContainer }}
          >
            <div className="logo" />
            <span>Virtual Car Marketplace</span>
          </Header>
          <Content className="content">{children}</Content>
        </Layout>
      </Col>
    </Row>
  );
};

export default Main;
