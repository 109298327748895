const data = [
  {
    mark: "Ford",
    model: "F-250 Super Duty",
    year: "2015",
    price: "$17,995",
    additional: "Est. $487/mo.*",
    mileage: "134,316 mi",
    location: "Santa Ana, CA",
    extra: "$3,131 below market",
    phone: "(570) 865-8471",
    vin: "1FT7X2A68FEC53883",
    images: [
      require("../../assets/cars/1.1.jpeg"),
      require("../../assets/cars/1.2.jpeg"),
      require("../../assets/cars/1.3.jpeg"),
      require("../../assets/cars/1.4.jpeg"),
    ],
    equipment: `
    Body type: pickup_truck
    Exterior color: White
    Interior color: Brown
    Mpg: 15 MPG
    Trim: Platinum Crew Cab 4WD
    Transmission: Automatic
    Fuel type: Biodiesel
    Engine: 440 hp 6.7L V8 Biodiesel
    Drive type: Four-Wheel Drive
    Doors: 4
    Leather Seats
    Sunroof/Moonroof
    Navigation System
    Bluetooth
    Backup Camera
    Remote Start
    Heated Seats
    Fuel tank size: 35.0
    Car condition: USED
    Front Legroom 41 in
    Back Legroom 42 in
    Bed length: 81 in
    4x4
    Air Conditioning
    AM/FM
    AM/FM Radio
    Backup Camera
    Bluetooth
    Cruise Control
    Diesel
    Fog Lights
    Front Heated Seat
    Keyless Entry
    Leather Seat
    Leather Seats
    Leather Steering Wheel
    Navigation
    Power Brakes
    Power Locks
    Power Seats
    Power Steering
    Power Sunroof
    Power Windows
    Running Boards
    Satellite Radio
    Steering Wheel Mounted Controls
    Tachometer
    Telescopic Steering Column
    Tilt Steering Column
    `,
  },
  {
    mark: "Porsche",
    model: "Macan T",
    year: "2023",
    price: "$59,894",
    additional: "Est. $1,620/mo.*",
    mileage: "6,664 mi",
    location: "Long Beach, CA",
    extra: "$2,106 below market",
    phone: "(570) 865-8471",
    vin: "WP1AA2A5XMDA00001",
    images: [
      require("../../assets/cars/2.1.png"),
      require("../../assets/cars/2.2.png"),
      require("../../assets/cars/2.3.png"),
      require("../../assets/cars/2.4.png"),
    ],
    equipment: `
    Body type: crossover
    Exterior color: White
    Interior color: Black
    Mpg: 20 MPG
    Trim: S AWD
    Transmission: Automatic
    Fuel type: Gasoline
    Engine: 340 hp 3L V6
    Drive type: All-Wheel Drive
    Doors: 4
    Leather Seats
    Memory Package
    Backup Camera
    Navigation System
    Premium Package
    Comfort Package
    Fuel tank size: 19.8
    Car condition: USED
    Cargo volume: 17 cu ft
    Premium Package Plus
    Wheels: 21" 911 Turbo Design
    Infotainment Package
    Porsche Torque Vectoring Plus (Ptv Plus)
    Rearview Camera W/Front, Rear Parkassist
    Bose Surround Sound System
    Porsche Active Suspension Management (Pasm)
    14-Way Adj Seats W/Comfort/Memory Pkg
    Trailer Coupling W/O Removable Ball Joint
    Voice Control
    Leather Seats
    Navigation System
    Light Comfort Package
    Wheel Hub Cover W/Colored Porsche Crest
    Rear Spoiler
    Black; Seat Trim
    White`,
  },
  {
    mark: "Porsche",
    model: "911 Turbo S Cabriolet AWD",
    year: "2014",
    price: "$140,888",
    additional: "Est. $3,820/mo.*",
    mileage: "8,000 mi",
    location: "Thousand Oaks, CA",
    extra: "$1,112 below market",
    phone: "(570) 865-8471",
    vin: "WP0CD2A91ES172000",
    images: [
      require("../../assets/cars/3.1.jpeg"),
      require("../../assets/cars/3.2.jpeg"),
      require("../../assets/cars/3.3.jpeg"),
      require("../../assets/cars/3.4.jpeg"),
    ],
    equipment: `
    Body type: coupe
    Exterior color: Black
    Interior color: Black
    Mpg: 26 MPG
    Trim: Carrera Coupe RWD
    Transmission: Automatic
    Fuel type: Gasoline
    Engine: 370 hp 3L H6
    Drive type: Rear-Wheel Drive
    Doors: 2
    Bluetooth
    Leather Seats
    Backup Camera
    Sunroof/Moonroof
    Navigation System
    Alloy Wheels
    Fuel tank size: 16.9
    Car condition: USED
    Front Legroom 41 in
    Cargo volume: 5 cu ft
    Leather Seats
    Navigation System
    Rear Spoiler
    Black
    Black; Leather Seat Trim
    `,
  },
  {
    mark: "BMW",
    model: "X4 xDrive30i AWD",
    year: "2022",
    price: "$50,995",
    additional: "Est. $1,381/mo.*",
    mileage: "4,000 mi",
    location: "Santa Ana, CA",
    extra: "$1,112 below market",
    phone: "(570) 865-8471",
    vin: "5UXTY5C01N9E00001",
    images: [
      require("../../assets/cars/4.1.jpeg"),
      require("../../assets/cars/4.2.jpeg"),
      require("../../assets/cars/4.3.jpeg"),
    ],
    equipment: `
    Body type: crossover
    Exterior color: Black
    Interior color: Black
    Mpg: 24 MPG
    Trim: M40i AWD
    Transmission: Automatic
    Fuel type: Gasoline
    Engine: 355 hp 3L I6
    Drive type: All-Wheel Drive
    Doors: 4
    Sunroof/Moonroof
    Navigation System
    Alloy Wheels
    Bluetooth
    Backup Camera
    Blind Spot Monitoring
    Premium Package
    Heated Seats
    Fuel tank size: 17.2
    Car condition: USED
    Front Legroom 40 in
    Back Legroom 35 in
    Cargo volume: 18 cu ft
    BLACK W/CONTRAST STITCH  VERNASCA LEATHER UPHOLSTERY
    BLACK SAPPHIRE METALLIC
    HEATED STEERING WHEEL
    ALUMINUM TRIM
    DRIVING ASSISTANCE PACKAGE  Lane Departure Warning  Active Blind Spot Detection  Active Driving Assistant  Cross-Traffic Alert Rear  For vehicles equipped w/navigation  speed limit info is additionally included
    WHEELS: 21" X 8.5" FR/21" X 9.5" RR (STYLE 718M)  M double-spoke cerium gray  Increased Top Speed Limiter  Tires: 245/40R21 Fr, 275/35R21 Rr Perf Run-Flat
    HEATED FRONT SEATS
    PREMIUM PACKAGE  Gesture Control  Head-Up Display  Heated Steering Wheel  Heated Front Seats
    AMBIENT LIGHTING
    Turbocharged
    All Wheel Drive
    Active Suspension
    Power Steering
    ABS
    4-Wheel Disc Brakes
    Brake Assist
    Brake Actuated Limited Slip Differential
    Aluminum Wheels
    Tires Front Performance
    Tires Rear Performance
    Sun/Moonroof
    Generic Sun/Moonroof
    Panoramic Roof
    Heated Mirrors
    Power Mirror(s)
    Integrated Turn Signal Mirrors
    Power Folding Mirrors
    Rear Defrost
    Privacy Glass
    Intermittent Wipers
    Variable Speed Intermittent Wipers
    Rain Sensing Wipers
    Rear Spoiler
    Remote Trunk Release
    Power Liftgate
    Power Door Locks
    Fog Lamps
    Daytime Running Lights
    Automatic Headlights
    Headlights-Auto-Leveling
    LED Headlights
    Automatic Highbeams
    AM/FM Stereo
    Satellite Radio
    HD Radio
    Requires Subscription
    MP3 Player
    Steering Wheel Audio Controls
    Auxiliary Audio Input
    AM/FM Stereo
    Satellite Radio
    HD Radio
    Requires Subscription
    Bucket Seats
    Power Driver Seat
    Power Passenger Seat
    Pass-Through Rear Seat
    Rear Bench Seat
    Adjustable Steering Wheel
    Trip Computer
    Power Windows
    Leather Steering Wheel
    Keyless Entry
    Power Door Locks
    Keyless Entry
    Power Door Locks
    Keyless Start
    Hands-Free Liftgate
    Universal Garage Door Opener
    Cruise Control
    Climate Control
    Multi-Zone A/C
    A/C
    Rear A/C
    Auto-Dimming Rearview Mirror
    Driver Vanity Mirror
    Passenger Vanity Mirror
    Driver Illuminated Vanity Mirror
    Passenger Illuminated Visor Mirror
    Floor Mats
    Cargo Shade
    Mirror Memory
    Seat Memory
    Keyless Start
    Navigation System
    Telematics
    Requires Subscription
    Smart Device Integration
    Smart Device Integration
    MP3 Player
    Bluetooth Connection
    Smart Device Integration
    Power Windows
    Power Door Locks
    Trip Computer
    Driver Adjustable Lumbar
    Passenger Adjustable Lumbar
    Security System
    Immobilizer
    Traction Control
    Stability Control
    Traction Control
    Front Side Air Bag
    Rear Parking Aid
    Front Collision Mitigation
    Driver Monitoring
    Tire Pressure Monitor
    Driver Air Bag
    Passenger Air Bag
    Front Head Air Bag
    Rear Head Air Bag
    Passenger Air Bag Sensor
    Child Safety Locks
    Back-Up Camera
  `,
  },
  {
    mark: "Toyota",
    model: "Prius Three",
    year: "2012",
    price: "$10,995",
    additional: "Est. $298/mo.*",
    mileage: "90,000 mi",
    location: "Van Nuys, CA",
    extra: "$1,112 below market",
    phone: "(570) 865-8471",
    vin: "JTDKN3DU1C1520001",
    images: [
      require("../../assets/cars/5.1.jpeg"),
      require("../../assets/cars/5.2.jpeg"),
      require("../../assets/cars/5.3.jpeg"),
    ],
    equipment: `
    Body type: hatchback
    Exterior color: White (Blizzard Pearl)
    Interior color: Misty Gray
    Mpg: 50 MPG
    Trim: Three
    Transmission: Continuously Variable Transmission
    Fuel type: Hybrid
    Engine: 134 hp 1.8L I4 Hybrid
    Drive type: Front-Wheel Drive
    Doors: 4
    Navigation System
    Bluetooth
    Backup Camera
    Fuel tank size: 11.9
    Car condition: USED
    Front Legroom 42 in
    Back Legroom 36 in
    Cargo volume: 21 cu ft
    Rear mounted camera
    Brake assist system
    Entune selective service internet access
    Cruise control with steering wheel mounted controls
    Integrated navigation system with voice activation
    Smart key with hands-free access and push button start
    Bluetooth wireless audio streaming
    Immobilizer
    Bluetooth handsfree wireless device connectivity
    Entune smart device app link
    AM/FM/HD/Satellite radio
    Entune external memory control
    Internet radio capability
    Electronic stability control system
    External acoustic pedestrian alert
    Hill start assist
    Automatic climate control
    LED daytime running lights
    LED brake lights
    Manual rear child safety door locks
    Regenerative brakes
    Heated driver and passenger side door mirrors
    Manual tilting steering wheel
    Manual telescopic steering wheel
    Auto stop-start engine
    Pearlcoat paint
    Full wheel covers
    15 x 6-inch front and rear aluminum wheels
    P195/65SR15 AS BSW front and rear tires
    Cloth front seat upholstery
    7 airbags
    Driver front impact airbag
    Seat mounted side impact driver airbag
    Curtain first and second-row overhead airbags
    Passenger front impact airbag
    Seat mounted side impact front passenger airbag
    Airbag occupancy sensor
    Driver side knee airbag
    Auto-locking doors
    CVT
    Cloth rear seat upholstery
    Cabin air filter
    Rear under seat climate control ducts
    Steering wheel mounted climate controls
    Exterior temperature display
    Transmission electronic control
    Automatic
    Projector beam headlights
    Halogen headlights
    `,
  },
  {
    mark: "Toyota",
    model: "Corolla LE",
    year: "2022",
    price: "$21,995",
    additional: "Est. $596/mo.*",
    mileage: "2,000 mi",
    location: "Santa Ana, CA",
    extra: "$1,112 below market",
    phone: "(570) 865-8471",
    vin: "JTDEPMAE8NJ00001",
    images: [
      require("../../assets/cars/6.1.jpeg"),
      require("../../assets/cars/6.2.jpeg"),
      require("../../assets/cars/6.3.jpeg"),
    ],
    equipment: `
    Body type: sedan
    Exterior color: White
    Interior color: Black
    Mpg: 35 MPG
    Trim: SE FWD
    Transmission: Continuously Variable Transmission
    Fuel type: Gasoline
    Engine: 2L I4
    Drive type: Front-Wheel Drive
    Doors: 4
    Backup Camera
    Android Auto
    Alloy Wheels
    CarPlay
    Fuel tank size: 13.2
    Car condition: USED
    Front Legroom 42 in
    Back Legroom 34 in
    Cargo volume: 13 cu ft
    6 Speakers
    AM/FM radio: SiriusXM
    Radio data system
    Radio: Audio
    Air Conditioning
    Automatic temperature control
    Rear window defroster
    Power steering
    Power windows
    Remote keyless entry
    Steering wheel mounted audio controls
    Four wheel independent suspension
    Traction control
    4-Wheel Disc Brakes
    ABS brakes
    Dual front impact airbags
    Dual front side impact airbags
    Emergency communication system: Safety Connect with 1-year trial
    Front anti-roll bar
    Knee airbag
    Low tire pressure warning
    Occupant sensing airbag
    Overhead airbag
    Rear anti-roll bar
    Rear side impact airbag
    Brake assist
    Electronic Stability Control
    Exterior Parking Camera Rear
    Auto High-beam Headlights
    Delay-off headlights
    Fully automatic headlights
    Panic alarm
    Security system
    Speed control
    Bumpers: body-color
    Heated door mirrors
    Power door mirrors
    Spoiler
    Turn signal indicator mirrors
    Apple CarPlay/Android Auto
    Driver door bin
    Driver vanity mirror
    Front reading lights
    Illuminated entry
    Leather Shift Knob
    Outside temperature display
    Passenger vanity mirror
    Rear seat center armrest
    Tachometer
    Telescoping steering wheel
    Tilt steering wheel
    Trip computer
    Front Bucket Seats
    Front Center Armrest
    Front Sport Seats
    Premium Fabric Seat Trim
    Split folding rear seat
    Passenger door bin
    18" Machined Alloy Wheels
    Alloy wheels
    Variably intermittent wipers
    `,
  },
];

export const types = [
  "I'm interested in this",
  "I'd like to know your best price for this",
  "I'd like to test drive this",
  "I'd like a history report for this vehicle",
];

export default data;
